var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { updateScore } from "@/dao/match";
import { useState } from "react";
var useScoreForm = function (data, matchId) {
    var games = data.rounds[data.round].games;
    var _a = useState(games.map(function () { return true; })), isScoreValidForTeam1 = _a[0], setScoreValidForTeam1 = _a[1];
    var _b = useState(games.map(function () { return true; })), isScoreValidForTeam2 = _b[0], setScoreValidForTeam2 = _b[1];
    var _c = useState(games.map(function () { return ({ team1: 0, team2: 0 }); })), scores = _c[0], setScore = _c[1];
    var updateScoreTeam1 = function (gameIndex, score) {
        var _a;
        scores[gameIndex] = { team1: score, team2: (_a = scores[gameIndex].team2) !== null && _a !== void 0 ? _a : 0 };
        setScore(__spreadArray([], scores, true));
        var newValidationState = __spreadArray([], isScoreValidForTeam1, true);
        newValidationState[gameIndex] =
            score % 1 === 0 && score > -1 && score < 100;
        setScoreValidForTeam1(newValidationState);
    };
    var updateScoreTeam2 = function (gameIndex, score) {
        var _a;
        scores[gameIndex] = { team1: (_a = scores[gameIndex].team1) !== null && _a !== void 0 ? _a : 0, team2: score };
        setScore(__spreadArray([], scores, true));
        var newValidationState = __spreadArray([], isScoreValidForTeam2, true);
        newValidationState[gameIndex] =
            score % 1 === 0 && score > -1 && score < 100;
        setScoreValidForTeam2(newValidationState);
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isScoreValidForTeam1.every(Boolean) ||
                        !isScoreValidForTeam2.every(Boolean)) {
                        return [2 /*return*/];
                    }
                    games.forEach(function (game, index) {
                        game.team1Score = scores[index].team1;
                        game.team2Score = scores[index].team2;
                    });
                    return [4 /*yield*/, updateScore(matchId, data.rounds, data.round)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        isScoreValidForTeam1: isScoreValidForTeam1,
        isScoreValidForTeam2: isScoreValidForTeam2,
        scores: scores,
        updateScoreTeam1: updateScoreTeam1,
        updateScoreTeam2: updateScoreTeam2,
        handleSubmit: handleSubmit,
    };
};
export default useScoreForm;

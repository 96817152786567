var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Box, TextField, Button } from "@mui/material";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { MAX_NAME_LENGTH } from "shared/utils/constants";
var PlayerInput = function (_a) {
    var playerName = _a.playerName, onPlayerNameChange = _a.onPlayerNameChange, addPlayer = _a.addPlayer, disabled = _a.disabled, isValidAmountOfPlayers = _a.isValidAmountOfPlayers;
    var _b = useState(false), isDiplayNameValid = _b[0], setValidDisplayName = _b[1];
    useEffect(function () {
        setValidDisplayName(playerName.length <= MAX_NAME_LENGTH);
    }, [playerName]);
    var handleUpdate = function () {
        if (isDiplayNameValid) {
            addPlayer();
        }
    };
    var handleChange = function (event) {
        onPlayerNameChange(event);
    };
    var playerInputLabel = disabled
        ? "Max players reached"
        : "Enter player name";
    return (_jsxs(Box, __assign({ sx: {
            display: "flex",
            alignItems: "center",
            minHeight: "10vh",
            justifyContent: "center",
        } }, { children: [_jsx(TextField, { sx: { width: "70%" }, label: playerInputLabel, value: playerName, onChange: handleChange, onKeyDown: function (e) {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        handleUpdate();
                    }
                }, margin: "normal", disabled: disabled, error: !isValidAmountOfPlayers || !isDiplayNameValid, helperText: !isValidAmountOfPlayers
                    ? "Invalid amount of players"
                    : !isDiplayNameValid
                        ? "Name must be 12 characters or less"
                        : "" }), _jsx(Button, __assign({ variant: "contained", color: "primary", sx: {
                    padding: "15px 6px",
                    marginLeft: "8px",
                    marginTop: !isValidAmountOfPlayers || !isDiplayNameValid ? 0 : "6px",
                    marginBottom: !isValidAmountOfPlayers || !isDiplayNameValid ? "16px" : 0,
                }, onClick: handleUpdate, disabled: !playerName.trim() || disabled || !isDiplayNameValid }, { children: _jsx(PersonAddAltIcon, {}) }))] })));
};
export default PlayerInput;

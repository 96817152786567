var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Box, Typography } from "@mui/material";
var CourtDisplay = function (_a) {
    var games = _a.games, playerList = _a.playerList;
    return (_jsx(_Fragment, { children: games.map(function (game, index) { return (_jsxs(Box, __assign({ sx: {
                border: "2px solid",
                padding: 2,
                marginBottom: 2,
            } }, { children: [_jsxs(Typography, __assign({ variant: "h6", align: "center", gutterBottom: true }, { children: ["COURT ", index + 1] })), _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between" }, { children: [_jsxs(Box, __assign({ textAlign: "center" }, { children: [_jsx(Typography, { children: playerList[game.team1[0]].name }), _jsx(Typography, { children: playerList[game.team1[1]].name })] })), _jsx(Box, __assign({ textAlign: "center" }, { children: _jsx(Typography, { children: "VS" }) })), _jsxs(Box, __assign({ textAlign: "center" }, { children: [_jsx(Typography, { children: playerList[game.team2[0]].name }), _jsx(Typography, { children: playerList[game.team2[1]].name })] }))] }))] }), index)); }) }));
};
export default CourtDisplay;

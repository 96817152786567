var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useAuth } from "@/context/AuthContext";
import Loading from "@/components/Loading";
import { Container, Box, Typography, Button, Paper, Avatar, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { randomAlphaNumeric } from "shared/utils/shared";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { auth } from "../../utils/firestore";
function UserLanding() {
    var _a = useAuth(), user = _a.user, loading = _a.loading;
    var navigate = useNavigate();
    if (loading)
        return _jsx(Loading, {});
    if (!user)
        return _jsx("h1", { children: "No User" });
    var handleCreateMatch = function () { return navigate("/match/".concat(randomAlphaNumeric(5))); };
    var handleJoinMatch = function () { return navigate("/join"); };
    var handleUserSettings = function () { return navigate("/user/settings"); };
    var handleUserProfile = function () { return navigate("/user/".concat(user.uid)); };
    var handleLogout = function () { return auth.signOut(); };
    // Should have 3 options
    // 1. Create game, this should navigate to /match/{matchId}
    // 2. Join game, this should navigate to /match/{matchId}
    // 3. User settings, this should navigate to /user/settings
    // 4. Leaderboards, this should navigate to /leaderboards
    var MenuButton = function (_a) {
        var icon = _a.icon, text = _a.text, onClick = _a.onClick;
        return (_jsx(Button, __assign({ variant: "contained", startIcon: icon, onClick: onClick, sx: {
                width: "100%",
                justifyContent: "flex-start",
                padding: "15px 20px",
                marginBottom: 2,
                borderRadius: 2,
                backgroundColor: "primary.light",
                color: "primary.contrastText",
                "&:hover": {
                    backgroundColor: "primary.main",
                },
            } }, { children: text })));
    };
    return (_jsx(Container, __assign({ maxWidth: "sm", sx: { mt: 4, minHeight: "90vh" } }, { children: _jsxs(Paper, __assign({ elevation: 3, sx: { padding: 3, borderRadius: 4 } }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", mb: 4 }, { children: [_jsx(Avatar, { src: "/path/to/avatar.jpg", alt: user.displayName, sx: { width: 80, height: 80, marginRight: 2 } }), _jsxs(Box, { children: [_jsx(Typography, __assign({ variant: "h4", gutterBottom: true }, { children: "Hos," })), _jsx(Typography, __assign({ variant: "h5", color: "text.secondary" }, { children: user.displayName }))] })] })), _jsxs(Box, { children: [_jsx(MenuButton, { icon: _jsx(AddIcon, {}), text: "Create Match", onClick: handleCreateMatch }), _jsx(MenuButton, { icon: _jsx(GroupIcon, {}), text: "Join Match", onClick: handleJoinMatch }), _jsx(MenuButton, { icon: _jsx(PersonIcon, {}), text: "User Profile", onClick: handleUserProfile }), _jsx(MenuButton, { icon: _jsx(SettingsIcon, {}), text: "User Settings", onClick: handleUserSettings }), _jsx(MenuButton, { icon: _jsx(LogoutIcon, {}), text: "Logout", onClick: handleLogout })] })] })) })));
}
export default UserLanding;

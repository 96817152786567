var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { createContext, useContext, useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
var NavigationHistoryContext = createContext(null);
export var NavigationHistoryProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), history = _b[0], setHistory = _b[1];
    var location = useLocation();
    useEffect(function () {
        setHistory(function (prevHistory) { return __spreadArray(__spreadArray([], prevHistory.slice(0, 3), true), [
            location.pathname,
        ], false); });
    }, [location]);
    return (_jsx(NavigationHistoryContext.Provider, __assign({ value: history }, { children: children })));
};
export var useNavigationHistory = function () {
    return useContext(NavigationHistoryContext);
};
export var BackButton = function (props) {
    var navigate = useNavigate();
    var history = useNavigationHistory();
    var location = useLocation();
    var filteredHistory = history.filter(function (x) { return x !== location.pathname; });
    var handleBackClick = function () {
        if (props.home) {
            navigate("/");
        }
        else {
            navigate(-1);
        }
    };
    return (_jsx(_Fragment, { children: (filteredHistory.length > 0 || props.home) && (_jsx(Button, __assign({ variant: "contained", sx: {
                padding: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "calc(100% - 32px)",
            }, onClick: handleBackClick }, { children: filteredHistory.length > 0 ? "Back" : "Home" }))) }));
};

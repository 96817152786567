var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import Display from "./pages/display/Display";
import MatchController from "./pages/match/MatchController";
import Login from "./pages/user/Login";
import ProtectedRoute from "@/components/ProtectedRoute";
import { AuthProvider } from "@/context/AuthContext";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import FirstTimeLogin from "./pages/user/onboarding";
import UserLanding from "./pages/user/userlanding";
import { randomAlphaNumeric } from "shared/utils/shared";
import JoinMatch from "./pages/user/joinmatch";
import UserSettings from "./pages/user/settings";
import { NavigationHistoryProvider } from "@/components/BackButton";
import UserProfile from "./pages/user/userprofile";
import { lightTheme, darkTheme } from "./theme/theme";
import { ThemeProvider } from "@mui/material/styles";
var root = createRoot(document.getElementById("root"));
root.render(_jsx(React.StrictMode, { children: _jsx(AuthProvider, { children: _jsx(BrowserRouter, { children: _jsx(NavigationHistoryProvider, { children: _jsx(ThemeProvider, __assign({ theme: false ? darkTheme : lightTheme }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/display", element: _jsx(Navigate, { to: "/display/".concat(randomAlphaNumeric(5)), replace: true }) }), _jsx(Route, { path: "/display/:matchId", element: _jsx(Display, {}) }), _jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/onboard", element: _jsx(FirstTimeLogin, {}) }), _jsx(Route, { path: "/match/:matchId", element: _jsx(ProtectedRoute, { children: _jsx(MatchController, {}) }) }), _jsx(Route, { path: "/join", element: _jsx(ProtectedRoute, { children: _jsx(JoinMatch, {}) }) }), _jsx(Route, { path: "/user/settings", element: _jsx(ProtectedRoute, { children: _jsx(UserSettings, {}) }) }), _jsx(Route, { path: "/user/:userId", element: _jsx(ProtectedRoute, { children: _jsx(UserProfile, {}) }) }), _jsx(Route, { path: "/*", element: _jsx(ProtectedRoute, { children: _jsx(UserLanding, {}) }) })] }) })) }) }) }) }));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AMERICANO_12_PLAYER, AMERICANO_16_PLAYER, AMERICANO_4_PLAYER, AMERICANO_8_PLAYER, } from "./constants";
import { shuffleArray } from "./shared";
export function calculateAverageUserGameScore(userGames) {
    var totalScore = 0;
    userGames.forEach(function (game) {
        totalScore += game.playerScore;
    });
    return totalScore / userGames.length;
}
export function calculateTotalPoints(userGames) {
    var totalScore = 0;
    userGames.forEach(function (game) {
        totalScore += game.playerScore;
    });
    return totalScore;
}
export function calculateAveragePointsPerGame(rounds) {
    var totalPoints = 0;
    var totalGames = 0;
    // Iterate through each round
    rounds.forEach(function (round) {
        // Iterate through each game within the round
        round.games.forEach(function (game) {
            // Sum points for both teams
            totalPoints += (game.team1Score + game.team2Score) / 2;
            totalGames += 1;
        });
    });
    // Calculate the average points per game
    var averagePointsPerGame = totalPoints / totalGames;
    return Math.round(averagePointsPerGame * 100) / 100;
}
export function findPlayerWithMostPlayedPoints(rounds) {
    var playerPoints = new Map();
    // Iterate through each round
    rounds.forEach(function (round) {
        // Iterate through each game within the round
        round.games.forEach(function (game) {
            // Sum points for team 1 players
            game.team1.forEach(function (playerIndex) {
                var points = game.team1Score + game.team2Score;
                if (!playerPoints.has(playerIndex)) {
                    playerPoints.set(playerIndex, points);
                }
                else {
                    playerPoints.set(playerIndex, playerPoints.get(playerIndex) + points);
                }
            });
            // Sum points for team 2 players
            game.team2.forEach(function (playerIndex) {
                var points = game.team1Score + game.team2Score;
                if (!playerPoints.has(playerIndex)) {
                    playerPoints.set(playerIndex, points);
                }
                else {
                    playerPoints.set(playerIndex, playerPoints.get(playerIndex) + points);
                }
            });
        });
    });
    // Find the player with the most played points
    var maxPoints = -Infinity;
    var playerWithMostPoints = null;
    playerPoints.forEach(function (points, playerIndex) {
        if (points > maxPoints) {
            maxPoints = points;
            playerWithMostPoints = { playerIndex: playerIndex, totalPoints: points };
        }
    });
    return playerWithMostPoints;
}
export function findPlayerWithLeastPlayedPoints(rounds) {
    var playerPoints = new Map();
    // Iterate through each round
    rounds.forEach(function (round) {
        // Iterate through each game within the round
        round.games.forEach(function (game) {
            // Sum points for team 1 players
            game.team1.forEach(function (playerIndex) {
                var points = game.team1Score + game.team2Score;
                if (!playerPoints.has(playerIndex)) {
                    playerPoints.set(playerIndex, points);
                }
                else {
                    playerPoints.set(playerIndex, playerPoints.get(playerIndex) + points);
                }
            });
            // Sum points for team 2 players
            game.team2.forEach(function (playerIndex) {
                var points = game.team1Score + game.team2Score;
                if (!playerPoints.has(playerIndex)) {
                    playerPoints.set(playerIndex, points);
                }
                else {
                    playerPoints.set(playerIndex, playerPoints.get(playerIndex) + points);
                }
            });
        });
    });
    // Find the player with the least played points
    var minPoints = Infinity;
    var playerWithLeastPoints = null;
    playerPoints.forEach(function (points, playerIndex) {
        if (points < minPoints) {
            minPoints = points;
            playerWithLeastPoints = { playerIndex: playerIndex, totalPoints: points };
        }
    });
    return playerWithLeastPoints;
}
export function getTeamWithLowestScore(rounds) {
    var minScore = Infinity;
    var teamWithMinScore = null;
    rounds.forEach(function (round, roundIndex) {
        round.games.forEach(function (game, gameIndex) {
            // Check team 1 score
            if (game.team1Score < minScore) {
                minScore = game.team1Score;
                teamWithMinScore = {
                    roundIndex: roundIndex,
                    gameIndex: gameIndex,
                    team: game.team1,
                    score: game.team1Score,
                };
            }
            // Check team 2 score
            if (game.team2Score < minScore) {
                minScore = game.team2Score;
                teamWithMinScore = {
                    roundIndex: roundIndex,
                    gameIndex: gameIndex,
                    team: game.team2,
                    score: game.team2Score,
                };
            }
        });
    });
    return teamWithMinScore;
}
export function getGameWithHighestPointDifference(rounds) {
    var maxPointDifference = -Infinity;
    var gameWithMaxPointDifference = null;
    rounds.forEach(function (round, roundIndex) {
        round.games.forEach(function (game, gameIndex) {
            var pointDifference = Math.abs(game.team1Score - game.team2Score);
            if (pointDifference > maxPointDifference) {
                maxPointDifference = pointDifference;
                gameWithMaxPointDifference = {
                    roundIndex: roundIndex,
                    gameIndex: gameIndex,
                    pointDifference: pointDifference,
                    game: game,
                };
            }
        });
    });
    return gameWithMaxPointDifference;
}
export function preparePlayerChartData(rounds, playerList) {
    var playerScoresPerRound = [];
    var playerCount = new Set();
    // Initialize player cumulative scores
    var cumulativeScores = new Map();
    // Process each round
    rounds.forEach(function (round) {
        var scoresThisRound = new Map();
        // Process each game within the round
        round.games.forEach(function (game) {
            game.team1.forEach(function (playerIndex) {
                playerCount.add(playerIndex);
                if (!cumulativeScores.has(playerIndex)) {
                    cumulativeScores.set(playerIndex, 0);
                }
                var newScore = (cumulativeScores.get(playerIndex) || 0) + game.team1Score;
                cumulativeScores.set(playerIndex, newScore);
                scoresThisRound.set(playerIndex, newScore);
            });
            game.team2.forEach(function (playerIndex) {
                playerCount.add(playerIndex);
                if (!cumulativeScores.has(playerIndex)) {
                    cumulativeScores.set(playerIndex, 0);
                }
                var newScore = (cumulativeScores.get(playerIndex) || 0) + game.team2Score;
                cumulativeScores.set(playerIndex, newScore);
                scoresThisRound.set(playerIndex, newScore);
            });
        });
        // Collect scores for this round
        playerScoresPerRound.push(Array.from(scoresThisRound.entries())
            .sort(function (a, b) { return a[0] - b[0]; })
            .map(function (_a) {
            var _ = _a[0], score = _a[1];
            return score;
        }));
    });
    // Initialize chart data for each player
    var playerChartData = Array.from(playerCount)
        .sort(function (a, b) { return a - b; })
        .map(function () { return ({ data: [0], label: "" }); });
    // Assign cumulative scores to chart data
    playerScoresPerRound.forEach(function (scores, roundIndex) {
        scores.forEach(function (score, playerIndex) {
            playerChartData[playerIndex].data[roundIndex + 1] = score;
            playerChartData[playerIndex].label = playerList[playerIndex];
        });
    });
    // Ensure all rounds are represented
    playerChartData.forEach(function (playerData) {
        for (var i = 0; i < rounds.length; i++) {
            if (playerData.data[i] === undefined) {
                playerData.data[i] = i > 0 ? playerData.data[i - 1] : 0;
            }
        }
    });
    return playerChartData;
}
export function calculatePlayerScores(rounds) {
    var playerStats = new Map();
    rounds.forEach(function (round) {
        round.games.forEach(function (game) {
            var team1Wins = game.team1Score > game.team2Score;
            var team2Wins = game.team2Score > game.team1Score;
            var draw = game.team2Score === game.team1Score;
            game.team1.forEach(function (playerIndex) {
                if (!playerStats.has(playerIndex)) {
                    playerStats.set(playerIndex, {
                        playerScore: 0,
                        gamesWon: 0,
                        gamesLost: 0,
                        gamesDraw: 0,
                        pointsConceded: 0,
                    });
                }
                var stats = playerStats.get(playerIndex);
                stats.playerScore += game.team1Score;
                stats.pointsConceded += game.team2Score;
                if (team1Wins) {
                    stats.gamesWon += 1;
                }
                else if (team2Wins) {
                    stats.gamesLost += 1;
                }
                else if (draw) {
                    stats.gamesDraw += 1;
                }
            });
            game.team2.forEach(function (playerIndex) {
                if (!playerStats.has(playerIndex)) {
                    playerStats.set(playerIndex, {
                        playerScore: 0,
                        gamesWon: 0,
                        gamesLost: 0,
                        gamesDraw: 0,
                        pointsConceded: 0,
                    });
                }
                var stats = playerStats.get(playerIndex);
                stats.playerScore += game.team2Score;
                stats.pointsConceded += game.team1Score;
                if (team2Wins) {
                    stats.gamesWon += 1;
                }
                else if (team1Wins) {
                    stats.gamesLost += 1;
                }
                else if (draw) {
                    stats.gamesDraw += 1;
                }
            });
        });
    });
    var result = [];
    playerStats.forEach(function (stats, playerIndex) {
        result.push(__assign({ playerIndex: playerIndex }, stats));
    });
    return result.sort(function (a, b) { return b.playerScore - a.playerScore; });
}
export function createPadelAmericanoMatchups(legnth) {
    var array = Array.from({ length: legnth }, function (_, index) { return index; });
    // Shuffle the array
    var shuffledPlayers = __spreadArray([0], shuffleArray(array), true);
    var roundDataPlayers = legnth === 4 ?
        AMERICANO_4_PLAYER :
        legnth === 8 ?
            AMERICANO_8_PLAYER :
            legnth === 12 ?
                AMERICANO_12_PLAYER :
                legnth === 16 ?
                    AMERICANO_16_PLAYER :
                    null;
    if (!roundDataPlayers)
        throw new Error("Invalid number of players");
    return shuffleArray(roundDataPlayers.map(function (roundData) {
        return createRoundData(shuffledPlayers, roundData);
    }));
}
function createRoundData(players, selectedGroup) {
    return {
        games: createGameData(players, selectedGroup),
    };
}
function createGameData(players, selectedGroup) {
    return shuffleArray(selectedGroup.map(function (selected) { return createGameObject(players, selected); }));
}
function createGameObject(players, selected) {
    return {
        team1: shuffleArray([players[selected[0]], players[selected[1]]]),
        team2: shuffleArray([players[selected[2]], players[selected[3]]]),
        team1Score: 0,
        team2Score: 0,
    };
}

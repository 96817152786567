var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { listenToMatch, updatePlayers } from "@/dao/match";
import { useAuth } from "@/context/AuthContext";
import Loading from "@/components/Loading";
import { MatchState } from "shared/models/matchModels";
import OwnerLobby from "./owner/lobby";
import RoundStart from "./owner/startround";
import RoundInProgress from "./owner/roundinprogress";
import PostMatch from "../display/postmatch";
import CreateMatch from "./creatematch";
import SpectatorLobby from "./spectator/lobby";
import SpectatorInfo from "./spectator/info";
function MatchController() {
    var matchId = useParams().matchId;
    var _a = useAuth(), user = _a.user, loading = _a.loading;
    var _b = useState(null), matchData = _b[0], setMatchData = _b[1];
    var _c = useState(true), loadingMatch = _c[0], setLoadingMatch = _c[1];
    useEffect(function () {
        if (matchId) {
            var unsubscribe_1 = listenToMatch(matchId, function (data) {
                setMatchData(data);
                setLoadingMatch(false);
            });
            return function () { return unsubscribe_1(); };
        }
    }, [matchId]);
    if (loading || loadingMatch)
        return _jsx(Loading, {});
    if (!user || !matchId)
        return _jsx(Navigate, { to: "/error" });
    if (!matchData)
        return _jsx(CreateMatch, { matchId: matchId });
    // check if user is part of game
    var currentUser = matchData.playerList.find(function (player) { return player.uid === user.uid; });
    if (currentUser === undefined) {
        // user is not part of game, add them, if cannot, just let them spectate
        if (matchData.state === MatchState.NEW &&
            matchData.players > matchData.playerList.length) {
            updatePlayers(matchId, __spreadArray(__spreadArray([], matchData.playerList, true), [
                { name: user.displayName, uid: user.uid, rank: 0 },
            ], false));
            return _jsx(Loading, {});
        }
    }
    if (matchData.owner === (currentUser === null || currentUser === void 0 ? void 0 : currentUser.uid)) {
        // user is owner of game
        switch (matchData.state) {
            case MatchState.NEW:
                return _jsx(OwnerLobby, { data: matchData, matchId: matchId });
            case MatchState.READY:
                return _jsx(RoundStart, { data: matchData, matchId: matchId });
            case MatchState.IN_PROGRESS:
                return _jsx(RoundInProgress, { data: matchData, matchId: matchId });
            case MatchState.FINISHED:
                return _jsx(PostMatch, { data: matchData });
        }
    }
    else {
        // user is just a player
        switch (matchData.state) {
            case MatchState.NEW:
                return _jsx(SpectatorLobby, { data: matchData, matchId: matchId });
            case MatchState.READY:
            case MatchState.IN_PROGRESS:
                return _jsx(SpectatorInfo, { data: matchData, matchId: matchId });
            case MatchState.FINISHED:
                return _jsx(PostMatch, { data: matchData });
        }
    }
}
export default MatchController;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Box, Typography, Container } from "@mui/material";
import PlayerList from "@/components/PlayerList";
var SpectatorLobby = function (_a) {
    var matchId = _a.matchId, data = _a.data;
    return (_jsxs(Container, __assign({ maxWidth: "sm", sx: {
            mt: 4,
            minHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            overflowX: "hidden",
        } }, { children: [_jsx(Box, { children: _jsxs(Typography, __assign({ variant: "h4", gutterBottom: true }, { children: [data.sessionName, " (", matchId, ")"] })) }), _jsx(Box, __assign({ component: "form", noValidate: true, autoComplete: "off", sx: { flex: 1, mb: 2 } }, { children: _jsx(PlayerList, { players: data.playerList }) }))] })));
};
export default SpectatorLobby;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { collection, doc, getDoc, getDocs, setDoc, updateDoc, query, limit, orderBy, } from "firebase/firestore";
import { db } from "../utils/firestore";
var usersCollection = collection(db, "users");
export var fetchUser = function (uid) { return __awaiter(void 0, void 0, Promise, function () {
    var userDoc, userSnapshot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userDoc = doc(usersCollection, uid);
                return [4 /*yield*/, getDoc(userDoc)];
            case 1:
                userSnapshot = _a.sent();
                if (userSnapshot.exists()) {
                    return [2 /*return*/, userSnapshot.data()];
                }
                else {
                    return [2 /*return*/, null];
                }
                return [2 /*return*/];
        }
    });
}); };
export var createUser = function (uid, data) { return __awaiter(void 0, void 0, Promise, function () {
    var userDoc;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userDoc = doc(usersCollection, uid);
                return [4 /*yield*/, setDoc(userDoc, data)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var updateUser = function (uid, data) { return __awaiter(void 0, void 0, Promise, function () {
    var userDoc;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userDoc = doc(usersCollection, uid);
                return [4 /*yield*/, updateDoc(userDoc, __assign({}, data))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var getUserGames = function (uid) { return __awaiter(void 0, void 0, Promise, function () {
    var userGamesCollection, userGamesSnapshot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userGamesCollection = query(collection(usersCollection, uid, "matches"), orderBy("created_at"), limit(5));
                return [4 /*yield*/, getDocs(userGamesCollection)];
            case 1:
                userGamesSnapshot = _a.sent();
                return [2 /*return*/, userGamesSnapshot.docs.map(function (doc) { return doc.data(); })];
        }
    });
}); };
export var getUserRankLeaderboard = function () { return __awaiter(void 0, void 0, Promise, function () {
    var userRankLeaderboardQuery, userRankLeaderboardSnapshot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userRankLeaderboardQuery = query(usersCollection, orderBy("rank"), limit(10));
                return [4 /*yield*/, getDocs(userRankLeaderboardQuery)];
            case 1:
                userRankLeaderboardSnapshot = _a.sent();
                return [2 /*return*/, userRankLeaderboardSnapshot.docs.map(function (doc) { return doc.data(); })];
        }
    });
}); };

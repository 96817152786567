var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, TextField, Slider, Typography, Container, Button, Paper, useTheme, Snackbar, } from "@mui/material";
import { createMatch } from "@/dao/match";
import { useAuth } from "@/context/AuthContext";
import useMatchForm from "@/hooks/useMatchForm";
import { BackButton } from "@/components/BackButton";
import CreateIcon from "@mui/icons-material/Create";
import GroupIcon from "@mui/icons-material/Group";
var CreateMatch = function (_a) {
    var matchId = _a.matchId;
    var user = useAuth().user;
    var theme = useTheme();
    var _b = useMatchForm({}), sessionName = _b.sessionName, players = _b.players, isSessionNameValid = _b.isSessionNameValid, handleSessionNameChange = _b.handleSessionNameChange, handlePlayersChange = _b.handlePlayersChange, validateSessionName = _b.validateSessionName;
    var _c = React.useState(false), showSnackbar = _c[0], setShowSnackbar = _c[1];
    var handleCreateMatch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var matchData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateSessionName()) {
                        setShowSnackbar(true);
                        return [2 /*return*/];
                    }
                    matchData = {
                        sessionName: sessionName,
                        players: players,
                        host: { name: user.displayName, uid: user.uid, rank: 0 },
                        gameRank: 0,
                    };
                    return [4 /*yield*/, createMatch(matchId, matchData)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Container, __assign({ maxWidth: "sm", sx: { mt: 4, minHeight: "90vh" } }, { children: [_jsxs(Paper, __assign({ elevation: 3, sx: {
                    padding: 3,
                    borderRadius: 4,
                    backgroundColor: theme.palette.background.paper,
                    background: "linear-gradient(145deg, ".concat(theme.palette.background.paper, ", ").concat(theme.palette.background.default, ")"),
                } }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", mb: 4 }, { children: [_jsx(CreateIcon, { sx: { fontSize: 40, color: "primary.main", mr: 2 } }), _jsx(Typography, __assign({ variant: "h4", color: "primary" }, { children: "Create Match" }))] })), _jsxs(Box, __assign({ component: "form", noValidate: true, autoComplete: "off", sx: { mb: 2 } }, { children: [_jsx(TextField, { fullWidth: true, label: "Name of Session", value: sessionName, onChange: handleSessionNameChange, margin: "normal", onKeyDown: function (e) {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleCreateMatch();
                                    }
                                }, error: !isSessionNameValid, helperText: !isSessionNameValid
                                    ? "Session name must be greater than 1 character"
                                    : "", variant: "outlined" }), _jsxs(Box, __assign({ mt: 3 }, { children: [_jsxs(Typography, __assign({ gutterBottom: true }, { children: ["Number of Players: ", players] })), _jsxs(Box, __assign({ display: "flex", alignItems: "center" }, { children: [_jsx(GroupIcon, { sx: { mr: 2, color: "primary.main" } }), _jsx(Slider, { value: players, onChange: handlePlayersChange, "aria-labelledby": "players-slider", valueLabelDisplay: "auto", step: 4, marks: true, min: 4, max: 16, defaultValue: 8, sx: { flex: 1 } })] }))] })), _jsx(Button, __assign({ variant: "contained", sx: {
                                    mt: 3,
                                    padding: "15px",
                                    width: "100%",
                                }, onClick: handleCreateMatch, startIcon: _jsx(CreateIcon, {}) }, { children: "Create Match" }))] })), _jsx(Box, __assign({ mt: 2, display: "flex" }, { children: _jsx(BackButton, { home: true }) }))] })), _jsx(Snackbar, { open: showSnackbar, autoHideDuration: 3000, onClose: function () { return setShowSnackbar(false); }, message: "Please enter a valid session name" })] })));
};
export default CreateMatch;

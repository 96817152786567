var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { ScoreTable } from "@/components/ScoreTable";
import Timer from "@/components/Timer";
import CourtDisplay from "@/components/CourtDisplay";
import useCountdown from "@/hooks/useCountdown";
import { calculatePlayerScores } from "shared/utils/logic";
import { MatchFormat, MatchState } from "shared/models/matchModels";
import { ReactComponent as Logo } from "../../logo.svg";
var Info = function (_a) {
    var data = _a.data;
    var playersScore = calculatePlayerScores(data.rounds);
    var _b = useCountdown(data.limit, data.format === MatchFormat.TIMED &&
        data.state === MatchState.IN_PROGRESS), initialCountdown = _b.initialCountdown, showTimer = _b.showTimer, countdown = _b.countdown, timerFinished = _b.timerFinished, formatTime = _b.formatTime;
    return (_jsx(Box, __assign({ sx: { display: "flex", height: "100vh" } }, { children: _jsxs(Container, __assign({ maxWidth: "md", sx: { mt: 0, textAlign: "center" } }, { children: [_jsx(Logo, { width: "20%", height: "20%" }), showTimer ? (_jsxs(_Fragment, { children: [_jsxs(Typography, __assign({ variant: "h6", align: "center", gutterBottom: true }, { children: ["Round ", data.round + 1, "/", data.rounds.length] })), _jsx(Timer, { initialCountdown: initialCountdown, countdown: countdown, formatTime: formatTime })] })) : timerFinished ? (_jsx(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center" }, { children: _jsx(Typography, __assign({ variant: "h1", align: "center" }, { children: "Please submit scores" })) }))) : (_jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", mb: 4 }, { children: [_jsx(ScoreTable, { playersScore: playersScore, playerDatas: data.playerList, extended: false }), _jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsxs(Typography, __assign({ variant: "h6", align: "center", gutterBottom: true }, { children: ["Round ", data.round + 1, "/", data.rounds.length] })), _jsx(CourtDisplay, { games: data.rounds[data.round].games, playerList: data.playerList })] }))] })))] })) })));
};
export default Info;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
//
import React from "react";
import { Box, Typography, Container } from "@mui/material";
import CourtDisplay from "@/components/CourtDisplay";
import { ScoreTable } from "@/components/ScoreTable";
import { calculatePlayerScores } from "shared/utils/logic";
var SpectatorInfo = function (_a) {
    var matchId = _a.matchId, data = _a.data;
    var playersScore = calculatePlayerScores(data.rounds);
    return (_jsxs(Container, __assign({ maxWidth: "sm", sx: {
            mt: 4,
            minHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            overflowX: "hidden",
        } }, { children: [_jsx(Box, { children: _jsxs(Typography, __assign({ variant: "h4", gutterBottom: true }, { children: [data.sessionName, " (", matchId, ")"] })) }), _jsxs(Box, __assign({ component: "form", noValidate: true, autoComplete: "off", sx: { flex: 1, mb: 2 } }, { children: [_jsxs(Typography, __assign({ variant: "h6", align: "center", gutterBottom: true }, { children: ["Round ", data.round + 1, "/", data.rounds.length] })), _jsx(CourtDisplay, { games: data.rounds[data.round].games, playerList: data.playerList }), _jsx(ScoreTable, { playersScore: playersScore, playerDatas: data.playerList, extended: false })] }))] })));
};
export default SpectatorInfo;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { List, ListItem, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
var PlayerList = function (_a) {
    var players = _a.players, owner = _a.owner, removePlayer = _a.removePlayer;
    return (_jsx(List, { children: players
            .slice(0, players.length)
            .reverse()
            .map(function (player, index) { return (_jsx(ListItem, __assign({ secondaryAction: owner ? (_jsx(IconButton, __assign({ edge: "end", "aria-label": "delete", onClick: function () { return removePlayer(index); } }, { children: _jsx(DeleteIcon, {}) }))) : (_jsx(_Fragment, {})) }, { children: player.name }), index)); }) }));
};
export default PlayerList;

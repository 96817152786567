import { useState } from "react";
var useMatchForm = function (_a) {
    var _b = _a.initialSessionName, initialSessionName = _b === void 0 ? "" : _b, _c = _a.initialPlayers, initialPlayers = _c === void 0 ? 8 : _c;
    var _d = useState(initialSessionName), sessionName = _d[0], setSessionName = _d[1];
    var _e = useState(initialPlayers), players = _e[0], setPlayers = _e[1];
    var _f = useState(true), isSessionNameValid = _f[0], setValidSessionName = _f[1];
    var handleSessionNameChange = function (event) {
        setSessionName(event.target.value);
    };
    var handlePlayersChange = function (event, newValue) {
        setPlayers(newValue);
    };
    var validateSessionName = function () {
        var isValid = sessionName.length > 1;
        setValidSessionName(isValid);
        return isValid;
    };
    return {
        sessionName: sessionName,
        players: players,
        isSessionNameValid: isSessionNameValid,
        handleSessionNameChange: handleSessionNameChange,
        handlePlayersChange: handlePlayersChange,
        validateSessionName: validateSessionName,
    };
};
export default useMatchForm;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Box, TextField, Typography } from "@mui/material";
var PlayerScoreInput = function (_a) {
    var playerNames = _a.playerNames, score = _a.score, onChange = _a.onChange, isValid = _a.isValid;
    return (_jsxs(Box, __assign({ textAlign: "center" }, { children: [_jsxs(Typography, { children: [playerNames[0], "/", playerNames[1]] }), _jsx(TextField
            // type="number"
            , { 
                // type="number"
                value: score, onFocus: function (e) { return (e.target.value = ""); }, onBlur: function (e) {
                    return e.target.value === "" ? (e.target.value = "0") : e.target.value;
                }, onChange: function (e) {
                    //check if a number was typed
                    if (isNaN(Number(e.target.value))) {
                        e.preventDefault();
                    }
                    else {
                        onChange(e);
                    }
                }, error: !isValid, helperText: !isValid ? "Please enter a valid value" : "", variant: "outlined", fullWidth: true })] })));
};
export default PlayerScoreInput;

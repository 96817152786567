var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useAuth } from "@/context/AuthContext";
import Loading from "@/components/Loading";
import { Container, Box, Typography, Button, TextField, Paper, Avatar, IconButton, Snackbar, useTheme, } from "@mui/material";
import { MAX_NAME_LENGTH } from "shared/utils/constants";
import { updateUser } from "@/dao/user";
import { BackButton } from "@/components/BackButton";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
function UserSettings() {
    var _this = this;
    var _a = useAuth(), user = _a.user, loading = _a.loading, refreshUser = _a.refreshUser;
    var _b = useState(""), displayName = _b[0], setDisplayName = _b[1];
    var _c = useState(true), isDisplayNameValid = _c[0], setValidDisplayName = _c[1];
    var _d = useState(false), isEditing = _d[0], setIsEditing = _d[1];
    var _e = useState(false), showSnackbar = _e[0], setShowSnackbar = _e[1];
    var theme = useTheme();
    useEffect(function () {
        if (user) {
            setDisplayName(user.displayName || "");
        }
    }, [user]);
    if (loading)
        return _jsx(Loading, {});
    if (!user)
        return _jsx("h1", { children: "No User" });
    var handleUserSettingsUpdate = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!displayName || displayName.length > MAX_NAME_LENGTH) {
                        setValidDisplayName(false);
                        return [2 /*return*/];
                    }
                    setValidDisplayName(true);
                    return [4 /*yield*/, updateUser(user.uid, __assign(__assign({}, user), { displayName: displayName }))];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, refreshUser(user)];
                case 2:
                    _a.sent();
                    setIsEditing(false);
                    setShowSnackbar(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDisplayNameChange = function (event) {
        setDisplayName(event.target.value);
    };
    // Should have 3 options
    // 1. Create game, this should navigate to /match/{matchId}
    // 2. Join game, this should navigate to /match/{matchId}
    // 3. User settings, this should navigate to /user/settings
    // 4. Leaderboards, this should navigate to /leaderboards
    return (_jsxs(Container, __assign({ maxWidth: "sm", sx: { mt: 4, minHeight: "90vh" } }, { children: [_jsxs(Paper, __assign({ elevation: 3, sx: {
                    padding: 3,
                    borderRadius: 4,
                    backgroundColor: theme.palette.background.paper,
                } }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", mb: 4 }, { children: [_jsx(Avatar, { src: "/path/to/avatar.jpg", alt: user.displayName || "User", sx: { width: 80, height: 80, marginRight: 2 } }), _jsx(Typography, __assign({ variant: "h4", color: "primary" }, { children: "Settings" }))] })), _jsxs(Box, __assign({ component: "form", noValidate: true, autoComplete: "off", sx: { mb: 2 } }, { children: [_jsx(TextField, { fullWidth: true, label: "Display Name", value: displayName, onChange: handleDisplayNameChange, margin: "normal", error: !isDisplayNameValid, helperText: !isDisplayNameValid ? "Invalid Display Name" : "", disabled: !isEditing, InputProps: {
                                    endAdornment: (_jsx(IconButton, __assign({ onClick: function () { return setIsEditing(!isEditing); } }, { children: isEditing ? _jsx(SaveIcon, { color: "primary" }) : _jsx(EditIcon, {}) }))),
                                } }), _jsx(Button, __assign({ variant: "contained", sx: {
                                    mt: 2,
                                    padding: "15px",
                                    width: "100%",
                                }, onClick: handleUserSettingsUpdate, disabled: !isEditing, startIcon: _jsx(SaveIcon, {}) }, { children: "Update Settings" }))] })), _jsx(BackButton, { home: true })] })), _jsx(Snackbar, { open: showSnackbar, autoHideDuration: 3000, onClose: function () { return setShowSnackbar(false); }, message: "Settings updated successfully" })] })));
}
export default UserSettings;

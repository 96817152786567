var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, TextField, Button, Container, Typography } from "@mui/material";
import { useAuth } from "@/context/AuthContext";
import { createUser } from "@/dao/user";
import { MAX_NAME_LENGTH } from "shared/utils/constants";
var FirstTimeLogin = function () {
    var _a, _b;
    var _c = useState(""), displayName = _c[0], setDisplayName = _c[1];
    var _d = useState(true), isDiplayNameValid = _d[0], setValidDisplayName = _d[1];
    var _e = useAuth(), user = _e.user, firstTimeLogin = _e.firstTimeLogin, refreshUser = _e.refreshUser;
    var navigate = useNavigate();
    var location = useLocation();
    var from = ((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.pathname) || "/";
    var handleUpdate = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(user &&
                        displayName.length > 0 &&
                        displayName.length <= MAX_NAME_LENGTH)) return [3 /*break*/, 3];
                    setValidDisplayName(true);
                    return [4 /*yield*/, createUser(user.uid, {
                            displayName: displayName,
                            uid: user.uid,
                            rank: -1,
                        })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, refreshUser(user)];
                case 2:
                    _a.sent(); // Update the state to indicate first-time login is completed
                    return [3 /*break*/, 4];
                case 3:
                    setValidDisplayName(false);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!firstTimeLogin) {
            navigate(from, { replace: true });
        }
    }, [firstTimeLogin, navigate, from]);
    return (_jsxs(Container, __assign({ maxWidth: "sm", sx: { mt: 4 } }, { children: [_jsx(Typography, __assign({ variant: "h5", align: "center", gutterBottom: true }, { children: "Welcome! Please set your display name." })), _jsxs(Box, __assign({ component: "form", sx: {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                } }, { children: [_jsx(TextField, { label: "Display Name", value: displayName, onChange: function (e) { return setDisplayName(e.target.value); }, onKeyDown: function (e) {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                handleUpdate();
                            }
                        }, margin: "normal", fullWidth: true, error: !isDiplayNameValid, helperText: !isDiplayNameValid ? "Invalid Display Name" : "" }), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: handleUpdate, disabled: displayName.length > 12, sx: { mt: 2 } }, { children: "Update" }))] }))] })));
};
export default FirstTimeLogin;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Typography, Button, Container, Paper, useTheme, } from "@mui/material";
import useScoreForm from "@/hooks/useScoreForm";
import PlayerScoreInput from "@/components/PlayerScoreInput";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SendIcon from "@mui/icons-material/Send";
var RoundInProgress = function (_a) {
    var matchId = _a.matchId, data = _a.data;
    var theme = useTheme();
    var _b = useScoreForm(data, matchId), isScoreValidForTeam1 = _b.isScoreValidForTeam1, isScoreValidForTeam2 = _b.isScoreValidForTeam2, scores = _b.scores, updateScoreTeam1 = _b.updateScoreTeam1, updateScoreTeam2 = _b.updateScoreTeam2, handleSubmit = _b.handleSubmit;
    return (_jsx(Container, __assign({ maxWidth: "sm", sx: { mt: 4, mb: 4 } }, { children: _jsxs(Paper, __assign({ elevation: 3, sx: {
                padding: 3,
                borderRadius: 4,
                backgroundColor: theme.palette.background.paper,
                background: "linear-gradient(145deg, ".concat(theme.palette.background.paper, ", ").concat(theme.palette.background.default, ")"),
            } }, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", mb: 4 }, { children: [_jsx(SportsTennisIcon, { sx: { fontSize: 40, color: "primary.main", mr: 2 } }), _jsx(Typography, __assign({ variant: "h4", color: "primary" }, { children: data.sessionName }))] })), _jsxs(Typography, __assign({ variant: "h6", align: "center", gutterBottom: true }, { children: ["Round ", data.round + 1] })), data.rounds[data.round].games.map(function (game, index) { return (_jsxs(Paper, __assign({ elevation: 2, sx: {
                        p: 2,
                        mb: 2,
                        borderRadius: 2,
                        background: theme.palette.background.default,
                    } }, { children: [_jsxs(Typography, __assign({ variant: "subtitle1", align: "center", gutterBottom: true }, { children: ["Game ", index + 1] })), _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between" }, { children: [_jsx(Box, __assign({ flex: 1, mr: 1 }, { children: _jsx(PlayerScoreInput, { playerNames: [
                                            data.playerList[game.team1[0]].name,
                                            data.playerList[game.team1[1]].name,
                                        ], score: scores[index].team1, onChange: function (e) {
                                            return updateScoreTeam1(index, Number(e.target.value));
                                        }, isValid: isScoreValidForTeam1[index] }) })), _jsx(Box, __assign({ flex: 1, ml: 1 }, { children: _jsx(PlayerScoreInput, { playerNames: [
                                            data.playerList[game.team2[0]].name,
                                            data.playerList[game.team2[1]].name,
                                        ], score: scores[index].team2, onChange: function (e) {
                                            return updateScoreTeam2(index, Number(e.target.value));
                                        }, isValid: isScoreValidForTeam2[index] }) }))] }))] }), index)); }), _jsx(Button, __assign({ variant: "contained", color: "primary", onClick: handleSubmit, startIcon: _jsx(SendIcon, {}), sx: {
                        display: "block",
                        width: "100%",
                        fontSize: "1.2rem",
                        padding: "15px",
                        mt: 3,
                    } }, { children: "Submit Scores" }))] })) })));
};
export default RoundInProgress;

export var MAX_NAME_LENGTH = 13;
// Round Player Data
export var AMERICANO_4_PLAYER = [
    [[1, 2, 3, 4]],
    [[1, 3, 2, 4]],
    [[2, 3, 1, 4]],
];
export var AMERICANO_8_PLAYER = [
    [
        [1, 2, 3, 4],
        [5, 6, 7, 8],
    ],
    [
        [2, 3, 5, 7],
        [4, 1, 6, 8],
    ],
    [
        [3, 5, 4, 6],
        [7, 2, 1, 8],
    ],
    [
        [4, 7, 6, 2],
        [1, 5, 3, 8],
    ],
    [
        [5, 4, 7, 1],
        [6, 3, 2, 8],
    ],
    [
        [6, 1, 2, 5],
        [3, 7, 4, 8],
    ],
    [
        [7, 6, 1, 3],
        [2, 4, 5, 8],
    ],
];
export var AMERICANO_12_PLAYER = [
    [
        [1, 2, 3, 4],
        [5, 6, 7, 8],
        [9, 10, 11, 12],
    ],
    [
        [12, 2, 11, 8],
        [6, 7, 5, 1],
        [10, 3, 9, 4],
    ],
    [
        [3, 5, 10, 1],
        [4, 7, 9, 8],
        [2, 11, 12, 6],
    ],
    [
        [1, 11, 10, 8],
        [5, 12, 3, 6],
        [7, 9, 4, 2],
    ],
    [
        [12, 4, 5, 2],
        [11, 10, 1, 7],
        [6, 9, 3, 8],
    ],
    [
        [4, 1, 12, 7],
        [2, 10, 5, 8],
        [9, 3, 6, 11],
    ],
    [
        [7, 3, 12, 8],
        [1, 6, 4, 11],
        [10, 5, 2, 9],
    ],
    [
        [3, 12, 7, 10],
        [6, 2, 1, 9],
        [11, 5, 4, 8],
    ],
    [
        [5, 4, 11, 3],
        [2, 7, 6, 10],
        [9, 12, 1, 8],
    ],
    [
        [12, 1, 9, 5],
        [7, 11, 2, 3],
        [10, 4, 6, 8],
    ],
    [
        [11, 9, 7, 5],
        [3, 1, 2, 8],
        [4, 6, 10, 12],
    ],
];
export var AMERICANO_16_PLAYER = [
    [
        [1, 2, 3, 4],
        [5, 6, 7, 8],
        [9, 10, 11, 12],
        [13, 14, 15, 16],
    ],
    [
        [15, 13, 7, 3],
        [2, 11, 9, 16],
        [6, 1, 10, 14],
        [12, 4, 5, 8],
    ],
    [
        [4, 2, 13, 1],
        [3, 16, 12, 8],
        [11, 7, 15, 14],
        [10, 6, 5, 9],
    ],
    [
        [13, 4, 12, 15],
        [7, 5, 10, 1],
        [16, 11, 6, 2],
        [9, 14, 3, 8],
    ],
    [
        [15, 1, 9, 8],
        [14, 7, 4, 11],
        [5, 12, 13, 2],
        [6, 16, 3, 10],
    ],
    [
        [10, 2, 15, 8],
        [1, 5, 16, 7],
        [4, 14, 9, 13],
        [12, 3, 6, 11],
    ],
    [
        [13, 11, 10, 8],
        [3, 9, 4, 7],
        [2, 12, 14, 5],
        [16, 1, 15, 6],
    ],
    [
        [9, 15, 16, 5],
        [6, 7, 13, 8],
        [14, 2, 10, 4],
        [11, 3, 1, 12],
    ],
    [
        [4, 5, 6, 8],
        [1, 11, 13, 16],
        [15, 10, 14, 12],
        [7, 9, 2, 3],
    ],
    [
        [2, 7, 6, 14],
        [10, 13, 1, 3],
        [5, 15, 11, 9],
        [16, 12, 4, 8],
    ],
    [
        [14, 3, 16, 8],
        [12, 10, 7, 15],
        [11, 5, 4, 1],
        [13, 6, 2, 9],
    ],
    [
        [6, 4, 11, 15],
        [7, 12, 16, 2],
        [1, 9, 14, 8],
        [3, 13, 5, 10],
    ],
    [
        [2, 15, 1, 8],
        [5, 3, 14, 11],
        [4, 16, 7, 10],
        [9, 6, 12, 13],
    ],
    [
        [12, 9, 1, 7],
        [11, 10, 2, 8],
        [16, 14, 5, 13],
        [15, 4, 3, 6],
    ],
    [
        [14, 1, 12, 6],
        [10, 16, 9, 4],
        [7, 13, 11, 8],
        [3, 15, 2, 5],
    ],
];

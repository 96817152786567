import { MatchFormat } from "shared/models/matchModels";
import { useState, useEffect } from "react";
var useLobbyForm = function (data) {
    var _a = useState(MatchFormat.POINTS), format = _a[0], setFormat = _a[1];
    var _b = useState(16), limit = _b[0], setLimit = _b[1];
    var _c = useState(""), playerName = _c[0], setPlayerName = _c[1];
    var _d = useState(true), isValidAmountOfPlayers = _d[0], setValidPlayerCount = _d[1];
    useEffect(function () {
        if (format === MatchFormat.TIMED) {
            setLimit(12);
        }
        else {
            setLimit(16);
        }
    }, [format]);
    var handleFormatChange = function (event) {
        setFormat(event.target.value);
    };
    var handleLimitChange = function (event, newValue) {
        setLimit(newValue);
    };
    var handlePlayerNameChange = function (event) {
        setPlayerName(event.target.value);
        setValidPlayerCount(true);
    };
    var validatePlayerCount = function () {
        var isValid = data.playerList.length >= data.players;
        setValidPlayerCount(isValid);
        return isValid;
    };
    return {
        format: format,
        limit: limit,
        playerName: playerName,
        isValidAmountOfPlayers: isValidAmountOfPlayers,
        handleFormatChange: handleFormatChange,
        handleLimitChange: handleLimitChange,
        handlePlayerNameChange: handlePlayerNameChange,
        validatePlayerCount: validatePlayerCount,
    };
};
export default useLobbyForm;

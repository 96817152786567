var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, } from "@mui/material";
import { MatchFormat } from "shared/models/matchModels";
var FormatSelector = function (_a) {
    var format = _a.format, onChange = _a.onChange;
    return (_jsxs(FormControl, __assign({ component: "fieldset" }, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Match Format" })), _jsxs(RadioGroup, __assign({ "aria-label": "match format", name: "matchFormat", value: format, onChange: onChange }, { children: [_jsx(FormControlLabel, { value: MatchFormat.POINTS, control: _jsx(Radio, {}), label: "Points" }), _jsx(FormControlLabel, { value: MatchFormat.TIMED, control: _jsx(Radio, {}), label: "Timed" })] }))] })));
};
export default FormatSelector;

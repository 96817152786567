var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { MatchFormat } from "shared/models/matchModels";
import QRCode from "react-qr-code";
import { ReactComponent as Logo } from "../../logo.svg";
var MatchLobby = function (_a) {
    var data = _a.data, matchId = _a.matchId;
    var link = "http://" + window.location.host + "/match/" + matchId;
    return (_jsx(Box, __assign({ sx: { padding: 4, textAlign: "center" } }, { children: _jsxs(Grid, __assign({ container: true, direction: "column", alignItems: "center", spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true }, { children: _jsx(Logo, { width: "70%", height: "70%" }) })), _jsx(Grid, __assign({ item: true }, { children: _jsx(Typography, __assign({ variant: "h4" }, { children: data.sessionName })) })), _jsxs(Grid, __assign({ item: true }, { children: [_jsxs(Typography, __assign({ variant: "h6" }, { children: ["Format: ", data.format === MatchFormat.TIMED ? "Time" : "Points"] })), _jsxs(Typography, __assign({ variant: "h6" }, { children: ["Time Per Round: ", data.limit, " minutes"] })), _jsxs(Typography, __assign({ variant: "h6" }, { children: ["Player Amount: ", data.players] }))] })), _jsxs(Grid, __assign({ container: true, direction: "row" }, { children: [_jsx(Grid, __assign({ item: true, md: 6 }, { children: _jsx(QRCode, { value: link, size: 400 }) })), _jsx(Grid, __assign({ item: true, xs: 12, md: 6 }, { children: _jsxs(Paper, __assign({ elevation: 3 }, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Players" })), _jsx(Box, __assign({ sx: { padding: 2 } }, { children: data.playerList.length > 0 ? (data.playerList.map(function (player, index) { return (_jsx(Typography, { children: player.name }, index)); })) : (_jsx(Typography, { children: "No players yet" })) }))] })) }))] }))] })) })));
};
export default MatchLobby;

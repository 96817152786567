var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import QRCode from "react-qr-code";
import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as Logo } from "../../logo.svg";
var Landing = function (_a) {
    var matchId = _a.matchId;
    var link = "http://" + window.location.host + "/match/" + matchId;
    return (_jsx(Box, __assign({ sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
        } }, { children: _jsxs(Container, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Logo, { width: "25%", height: "25%" }), _jsx(Typography, __assign({ variant: "h5", gutterBottom: true }, { children: "Scan code to control APP" })), _jsx(Box, __assign({ sx: { marginTop: 4 } }, { children: _jsx(QRCode, { bgColor: "#282c34", fgColor: "#ffffff", level: "H", size: 500, value: link }) }))] })) })));
};
export default Landing;

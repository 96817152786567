var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { startRound } from "@/dao/match";
import { Box, Typography, Button, Container, Paper, useTheme, } from "@mui/material";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
var RoundStart = function (_a) {
    var matchId = _a.matchId, data = _a.data;
    var theme = useTheme();
    var onRoundStartClick = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, startRound(matchId)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    return (_jsx(Container, __assign({ maxWidth: "sm", sx: { height: "100vh", display: "flex", alignItems: "center" } }, { children: _jsx(Paper, __assign({ elevation: 3, sx: {
                padding: 4,
                borderRadius: 4,
                width: "100%",
                backgroundColor: theme.palette.background.paper,
                background: "linear-gradient(145deg, ".concat(theme.palette.background.paper, ", ").concat(theme.palette.background.default, ")"),
            } }, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "column", alignItems: "center" }, { children: [_jsx(SportsTennisIcon, { sx: { fontSize: 60, color: "primary.main", mb: 2 } }), _jsx(Typography, __assign({ variant: "h4", align: "center", color: "primary", gutterBottom: true }, { children: data.sessionName })), _jsxs(Typography, __assign({ variant: "h5", align: "center", color: "text.secondary", sx: { mb: 4 } }, { children: ["Round ", data.round + 1] })), _jsx(Button, __assign({ variant: "contained", size: "large", startIcon: _jsx(PlayArrowIcon, {}), sx: {
                            width: "100%",
                            py: 2,
                            fontSize: "1.2rem",
                        }, onClick: onRoundStartClick }, { children: "Start Round" }))] })) })) })));
};
export default RoundStart;
